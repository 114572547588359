<template>
  <!--body content wrap start-->
  <div class="main">
    <!--hero section start-->
    <section class="hero-section ptb-100  background-img full-screen" style="background: url('/img/hero-bg-shape-3.svg')no-repeat center center / cover">

      <div class="container">
        <div class="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
          <div class="col-md-5 col-lg-5">
            <div class="hero-content-left text-white">
              <h1 class="text-white">Richiedi il tuo preventivo gratuito!</h1>
              <p class="lead">
                Siamo la Business unit di Elmec Informatica che ha creato l’offerta Workplace migliore di sempre.
              </p>
            </div>
          </div>
          <div class="col-md-7 col-lg-7">
            <div style="padding: 16px" class="card login-signup-card shadow-lg mb-0">
                <hubspot-form :form-id="'faa2dc45-39f0-4cef-bf2d-2c58aa2e4841'"/>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-img-absolute d-none d-sm-block">
        <img style="padding-bottom: 30px" src="/img/white_bg2.svg" alt="wave shape" class="img-fluid">
      </div>
    </section>
    <!--hero section end-->

  </div>
  <!--body content wrap end-->
</template>

<script>
  import HubspotForm from './HubspotForm'
  import {commonMixins} from '../mixins/common-mixins'

  export default {
    name: 'Preventivo',
    components: {HubspotForm},
    mixins: [commonMixins],
    mounted: function () {
      this.goTopPage()
    }
  }
</script>

<style scoped>

</style>
