<template>
  <div id="hubspotForm"></div>
</template>

<script>
  export default {
    components: {},
    name: 'hubspot-signup',
    props: {
      formId: {
        type: String
      }
    },
    data () {
      return {}
    },
    mounted: function () {
      this.loadHbsptScript()
    },
    methods: {
      loadHbsptScript: function () {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/v2.js'
        document.body.appendChild(script)
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: 'na1',
              portalId: '5099766',
              formId: this.formId,
              target: '#hubspotForm'
            })
          }
        })
      }
    }
  }
</script>

<style lang="less">

</style>
